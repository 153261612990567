<template>
    <section class="settings-income">
        <h4 class="settings-income__title h3 sr-only">План доходов</h4>
        <spinner class="settings-income__spinner" v-if="status === 'loading'" />
        <error-message class="settings-income__error-message" v-else-if="status === 'error'">
            Не удалось загрузить план доходов.
        </error-message>
        <template v-else>
            <month-picker class="settings-income__datepicker settings-income__datepicker_plan"
                name="incomePlanDate"
                v-model="planDateModel"
            >
                Месяц плана:
            </month-picker>
            <p class="settings-income__tip">Сумма плана ресторана вводится в рублях.</p>
            <div class="settings-income__restaurants">
                <form class="settings-income__restaurant box"
                    @submit.prevent
                    v-for="restaurant in restaurants"
                    :key="restaurant.id"
                >
                    <h4 class="settings-income__restaurant-title h3">{{restaurant.title}}</h4>
                    <div class="settings-income__restaurant-controls">
                        <input class="settings-income__restaurant-input" type="text"
                            :class="{ 'settings-income__restaurant-input_invalid': restaurant.isValueInvalid }"
                            :value="restaurant.value"
                            @input="handlePlanInput(restaurant.id, $event)"
                        >
                        <button class="settings-income__restaurant-button settings-income__restaurant-button_save"
                            :disabled="restaurant.isButtonDisabled"
                            @click="submitRestaurantPlan(restaurant.id)"
                        >
                            {{restaurant.buttonText}}
                        </button>
                    </div>
                    <p class="settings-income__restaurant-error"
                        v-if="restaurant.error"
                    >
                        {{restaurant.error}}
                    </p>
                </form>
            </div>
        </template>
    </section>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    import MonthPicker from "@/components/MonthPicker";
    import { dateKey } from "@/helpers/daterange";

    export default {
        name: "SettingsIncome",
        components: {
            MonthPicker
        },
        data() {
            return {
                status: "loading",
                restaurants: []
            };
        },
        computed: {
            ...mapState({
                planDate: state => state.settings.income.plan.date,
                planData: state => state.settings.income.plan.data
            }),
            planDateModel: {
                get() {
                    return this.planDate;
                },
                set(date) {
                    this.setIncomePlanDate(date);
                }
            },
            planDateData() {
                return this.planData[dateKey(this.planDate)];
            }
        },
        methods: {
            ...mapMutations([
                "setIncomePlanDate",
            ]),
            ...mapActions([
                "requestIncomePlan",
                "saveRestaurantIncomePlanToDatabase"
            ]),
            showData() {
                this.restaurants = this.planDateData.map(restaurant => ({
                    id: restaurant.id,
                    title: restaurant.title,
                    value: restaurant.sum,
                    originalValue: restaurant.sum,
                    isValueInvalid: false,
                    isButtonDisabled: true,
                    buttonText: "Сохранить",
                    error: ""
                }));

                if (this.status !== "success") {
                    this.status = "success";
                }
            },
            showError() {
                this.status = "error";
            },
            loadPlanData() {
                this.status = "loading";
                this.requestIncomePlan(dateKey(this.planDate)).then(this.showData, this.showError);
            },
            handlePlanInput(restaurantId, { target: { value } }) {
                let restaurant = this.restaurants?.find(({ id }) => id === restaurantId);

                let isValueInvalid = isNaN(value) || Number(value) === 0;
                let isSameValue = Number(value) === restaurant.originalValue;

                restaurant.value = value;
                restaurant.isValueInvalid = isValueInvalid;
                restaurant.isButtonDisabled = isValueInvalid || isSameValue;
                restaurant.error = isValueInvalid ? "Некорректное значение" : "";
            },
            submitRestaurantPlan(restaurantId) {
                let restaurant = this.restaurants?.find(({ id }) => id === restaurantId);

                restaurant.error = "";
                restaurant.isButtonDisabled = true;
                restaurant.buttonText = "Сохранение...";

                this.saveRestaurantIncomePlanToDatabase({
                    date: dateKey(this.planDate),
                    eng: restaurantId,
                    sum: restaurant.value
                }).then(() => {
                    restaurant.originalValue =  Number(restaurant.value);
                }).catch(() => {
                    restaurant.error = "Не удалось сохранить план";
                    restaurant.isButtonDisabled = false;
                }).finally(() => {
                    restaurant.buttonText = "Сохранить";
                });
            }
        },
        watch: {
            planDateData(planDateData) {
                if (!planDateData) {
                    this.loadPlanData();
                } else {
                    this.showData();
                }
            }
        },
        created() {
            if (this.planDateData) {
                this.showData();
            } else {
                this.loadPlanData();
            }
        }
    }
</script>

<style lang="scss">
    .settings-income__title {
        margin-bottom: 10px;
    }
    .settings-income__datepicker {
        &_plan {
            margin-bottom: 10px;
        }
    }
    .settings-income__tip {
        margin-bottom: 10px;
    }
    .settings-income__restaurants {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
        }
    }
    .settings-income__restaurant {
        position: relative;
        padding: 16px 16px 30px;

        & + & {
            margin-top: 20px;

            @include desktop {
                 margin-top: 0;
            }
        }
    }
    .settings-income__restaurant-title {
        margin-bottom: 10px;
    }
    .settings-income__restaurant-controls {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .settings-income__restaurant-input {
        width: 150px;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;
        margin-right: 10px;

        font-size: 16px;
        line-height: 1;

        @include desktop {
            width: 190px;
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-income__restaurant-button {
        padding: 10px;
        border-radius: 6px;

        &[disabled] {
            background-color: $gray-form;
        }

        &_save {
            min-width: 100px;
            color: #fff;
            font-size: 14px;
            background-color: rgba($red-secondary, 0.7);

            @include desktop {
                min-width: 120px;
                font-size: 16px;
            }
        }
    }
    .settings-income__restaurant-error {
        color: $red-secondary;
        font-size: 14px;
        position: absolute;
        left: 16px;
        bottom: 8px;
    }
</style>
